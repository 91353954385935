$tds: false !default;

$gray-darker: #222; // lighten(#000, 13.5%)
$gray-dark: #333; // lighten(#000, 20%)
$gray: #555; // lighten(#000, 33.5%)
$gray-light: #999; // lighten(#000, 60%)
$gray-lighter: #eee; // lighten(#000, 93.5%)

$brand-primary: #2c97e8;
$brand-primary-text: #2c97e8;

$brand-success: #2bb367;
$brand-warning: #ff8c2c;
$brand-danger: #ff554c;
$brand-info: $brand-primary-text;

$tds-grey7: #1d2127;
$tds-grey6: #52575d;
$tds-grey5: #868c92;
$tds-grey4: #b9bec1;
$tds-grey3: #d2d7d9;
$tds-grey2: #ebeff0;
$tds-grey1: #f5f5f5;
$tds-grey0: #fafafa;

@if ($tds) {
  $gray-darker: $tds-grey7;
  $gray-dark: #333;
  $gray: $tds-grey6;
  $gray-light: $tds-grey5;
  $gray-lighter: $tds-grey2;

  $brand-primary: #00d9c5;
  $brand-primary-text: #06c4b0;
  $brand-success: #47b881;
  $brand-warning: #ffad0d;
  $brand-danger: #f64c4c;
  $brand-info: #155bd4;
}

$base-theme: $brand-primary-text;
$base-light: lighten($base-theme, 20%);
$base-lighter: lighten($base-theme, 40%);

$link-color: $base-theme;
$link-color-hover: darken($link-color, 15%);
$link-disabled-color: #ccc;
$inverted-link-color: lighten($base-theme, 10%);
$inverted-link-color-hover: lighten($base-theme, 20%);
$muted-color: rgba(0, 0, 0, 0.55);
$muted-link-color: $gray-light;
$muted-link-color-hover: darken($muted-link-color, 15%);
$code-color: #f5a122;
$code-bg: #fffaec;
$muted-section-bg: rgba(0, 0, 0, 0.024);
$section-bg: rgba(0, 0, 0, 0.04);

$separator-color: rgba(34, 36, 38, 0.1);
$muted-separator-color: rgba(34, 36, 38, 0.06);

@if ($tds) {
  $separator-color: $tds-grey3;
  $muted-separator-color: $tds-grey2;
}

$input-active-box-shadow-color: rgba(44, 151, 232, 0.1);
$input-active-box-shadow: 0 0 0 0.21428571em $input-active-box-shadow-color;

$emphasis-figure-font: if($tds, ('DIN Pro', sans-serif), (radikal, sans-serif));

$margin-sm: 4px;
$margin: 6px;
$margin-lg: 8px;
$margin-xl: 12px;
$margin-xxl: 24px;

$padding: 16px;
$padding-vertical: 14px;
$padding-lg: 28px;
$padding-lg-vertical: 18px;
$container-padding: $padding-vertical $padding;
$container-padding-lg: $padding-lg-vertical $padding-lg;

@mixin container {
  padding: $container-padding;
  @include media-tablet {
    padding: $container-padding-lg;
  }
}
@mixin fill-container {
  margin-right: -$padding;
  margin-left: -$padding;
  @include media-tablet {
    margin-right: -$padding-lg;
    margin-left: -$padding-lg;
  }
}

$pre-padding: 1em;

$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;

@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

$landscape-phone-width: 576px;
$tablet-width: 768px;
$desktop-width: 992px;

@mixin media-landscape-phone() {
  @media (orientation: landscape) and (min-width: $landscape-phone-width) {
    @content;
  }
}

@mixin media-tablet() {
  @media (min-width: $tablet-width) {
    @content;
  }
}

@mixin media-desktop() {
  @media (min-width: $desktop-width) {
    @content;
  }
}

$compact-header-height: 45px;
$header-height: 47px;

$compact-nav-height: 42px;
$nav-height: 45px;

$menu-width: 230px;

@mixin subnav($depth) {
  top: ($compact-nav-height * $depth);
  @include media-tablet() {
    top: ($nav-height * $depth);
  }
}
@mixin subTableHeader($depth) {
  &:global(.ui.table th) {
    @include subnav($depth);
  }
}
