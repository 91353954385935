$tds: false;
@import 'styles/variables';

.placeholder {
  margin-top: 24px;
}

.loader {
  &::after {
    border-color: #767676 transparent transparent !important;
  }
}

.list {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.team {
  max-width: 400px;
  min-width: 200px;
  padding: 16px 24px;
  position: relative;
  display: flex;
  align-items: center;
  flex-basis: 30%;
  color: #2c97e8;
  box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  .avatar {
    margin-right: 12px !important;
    width: 60px;
    max-height: 60px;
    border-radius: 4px;
  }

  .icon {
    margin-right: 12px !important;
    font-size: 50px !important;
    height: 50px;
    line-height: 1 !important;
  }

  .name {
    font-size: 20px;
  }

  .actions {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    > button {
      margin: 0 $margin;
    }
  }

  &:hover {
    .actions {
      display: flex;
    }
  }
}

.teamAvatar {
  max-width: 200px;
}

.members {
  display: flex;
  gap: $margin;
  flex-wrap: wrap;
  align-items: center;
}
.member {
  margin: $margin-xl 0;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 18px;
  border-radius: 4px;
  padding: 8px 12px;
  gap: 10px;
  width: 30%;
  box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;

  .memberAvatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

  span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .actions {
    align-items: center;
    justify-content: center;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    .actions {
      display: flex;
    }
  }
}

.addMember {
  font-size: 20px;
  display: flex;
  align-items: center;
  color: $brand-primary-text;
  cursor: pointer;

  :global {
    i {
      height: auto;
      font-size: 34px;
    }
  }
}

.search {
  display: flex;
  width: 200px;
  white-space: nowrap;
  gap: $margin;

  .input {
    flex: 1;
  }
}
