$tds: false;
@import 'styles/variables';
.label {
  white-space: nowrap;
}

.avatar {
  color: transparent;
  text-shadow: none;
  font-size: 0;
}
