$tds: false;
@import 'styles/variables';

.item {
  &:not(:last-child) {
    margin-right: $margin-lg;
  }

  .meta {
    margin: 0.5em 0 0.5em;
    font-size: 1em;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.6);
  }
}

.outdatedValue {
  opacity: 0.4;
}

.devided {
  padding-top: 0.75em;
  border-top: 1px solid $separator-color;
  margin-top: 1em;
}
