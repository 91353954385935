$tds: false;
@import 'styles/variables';

.container {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.chart {
  :global {
    .recharts-cartesian-axis-tick {
      font-size: 0.9em;
    }
    .recharts-legend-item {
      color: $gray-dark;
      &:hover .recharts-legend-item-text {
        color: $link-color-hover;
      }
    }
    .recharts-legend-item-text .disabled {
      color: $link-disabled-color !important;
    }
    .recharts-default-tooltip {
      padding: 12px !important;
    }
    .recharts-tooltip-label {
      padding-bottom: 6px;
    }
    .recharts-tooltip-item-list {
      font-size: 1.142857em;
    }
    .recharts-tooltip-item:last-child {
      padding-bottom: 0 !important;
    }
  }

  .largeValue {
    font-size: 1.6em;
  }
}

.tinyBarContainer {
  overflow: visible;
  :global {
    .recharts-tooltip-wrapper {
      top: 2.5em !important;
      z-index: 1;
    }
  }
  &.pointerCursor {
    :global {
      .recharts-surface {
        cursor: pointer;
      }
    }
  }
}

.pieChart {
  :global {
    .recharts-wrapper,
    .recharts-surface {
      width: 100% !important;
    }
    .recharts-pie:hover {
      .recharts-pie-sector {
        opacity: 0.333333;
      }
    }
    .recharts-pie-sector:hover {
      opacity: unset !important;
    }
    .recharts-pie-labels {
      pointer-events: none;
    }
  }
}

.barChart {
  :global {
    .recharts-tooltip-cursor {
      fill: #eee;
    }
    .yAxis .recharts-cartesian-axis-tick .tick {
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #666;
    }
  }
}

.zoomOut {
  position: absolute;
  top: 1em;
  right: 1em;
}

.nodata {
  display: flex;
  align-items: center;
  justify-content: center;
}
