$tds: false;
@import 'styles/variables';

.list {
  display: flex;
  flex-direction: column;
  .item {
    display: flex;
    align-items: center;
    min-height: 30px;
    label {
      flex-grow: 1;
    }
  }
  .checkbox {
    margin-right: $margin-lg;
  }
  label:not(:hover) .created {
    color: $muted-color;
  }
  .remark {
    margin-right: $margin-sm;
  }
  .created {
    margin-right: $margin-xl;
  }
}
.displayList {
  .payer_name,
  .remark {
    margin-left: $margin-sm;
  }
  .created {
    margin-left: $margin;
  }
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: .5em !important;
  padding-bottom: .5em !important;
  .item {
    display: inline-block;
    text-align: left;
  }
}
