$tds: false;
@use "sass:math";

@import 'styles/variables';
$hover-background: rgba(255, 255, 255, 0.05);
$border-color: rgba(255, 255, 255, 0.1);

$content-indent: 32px;

.menu {
  width: 100%;
  height: 100%;
  background: $gray-darker;
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.95);

  // reset
  :global {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    dt {
      padding: 4px 6px 4px ($content-indent - 6px);
      font-size: 13px;
      font-weight: normal;
      color: #999;
      margin-top: 0.5em;
      text-transform: uppercase;
    }
    dd {
      margin: 1em 0;
      + dt {
        margin-top: 2.2em;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }



  .link {
    display: block;
    position: relative;
    padding: 6px;
    padding-left: $content-indent;
    color: rgba(255, 255, 255, 0.75);
    &:hover {
      background: $hover-background;
      color: rgba(255, 255, 255, 0.95);
    }
    &.active {
      color: $inverted-link-color;
      &:after {
        content: '';
        position: absolute;
        width: 4px;
        height: 60%;
        left: ($content-indent - 14px);
        bottom: 20%;
        background: $base-theme;
      }
    }
    &.disabled {
      cursor: not-allowed;
      color: $gray-light;
    }

  }

  .accordionContainer {
    flex: 1;
    overflow: auto;
  }

  :global(.ui.accordion) {
    &.accordion {
      border-bottom: 1px solid $border-color;
    }

    .item {
      &:not(:first-child) {
        border-top: 1px solid $border-color;
      }
    }

    .title {
      // reset button
      appearance: none;
      border: none;
      background-color: transparent;
      width: 100%;

      display: flex;
      font-size: math.div(15em, 14);
      padding: 20px 18px;
      .icon {
        font-size: 1.2em;
        margin-right: $margin-lg;
      }
      .space {
        flex: 1;
      }
      .arrow {
        margin-right: 0;
        opacity: 0;
        transition: all 0.12s ease-in-out;
      }
      &:hover,
      &:active,
      &:focus {
        outline: none;
        background: $hover-background;
        .arrow {
          opacity: 0.8;
        }
      }
      &.current {
        color: $inverted-link-color;
      }
      &.active {
        .arrow {
          transform: rotate(180deg) translateY(-4px);
        }
      }
    }
    .content {
      padding: 2px 0 20px !important;

      > dl {
        margin: 0;
      }
    }
  }

  .secondary {
    margin: 1.2em 0 1.2em -4px;

    > .header {
      padding: 3px 6px 3px $content-indent;

      cursor: pointer;
      text-transform: uppercase;

      > * {
        display: inline-block;
        vertical-align: middle;
      }

      > .icon {
        font-size: 60%;
        color: $gray-light;
      }
    }

    .link {
      padding-left: ($content-indent + 14px);
      &.active:after {
        left: ($content-indent - 14px + 14px);
      }
    }

    &:not(.active) {
      > .content {
        display: none;
      }
    }
  }
}

.foldButton {
  // reset button
  appearance: none;
  cursor: pointer;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  padding: 0.5em 0;
  font-size: 1.2em;
  text-align: center;
  background-color: darken($color: $gray-darker, $amount: 4%);
  &:hover,
  &:active,
  &:focus {
    color: rgba(255, 255, 255, 0.95);
  }
}
