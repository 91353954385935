$tds: false;
@use "sass:math";

@import 'styles/variables';

:global(.ui.button).linkButton {
  appearance: none;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  color: $link-color;
  &:hover {
    color: $link-color-hover;
  }
  &.muted {
    color: $muted-link-color;
    &:hover {
      color: $muted-link-color-hover;
    }
  }
  &:not(.inline) {
    &:focus,
    &:active {
      background-color: #f0f0f0;
    }
  }

  &.inline {
    display: inline-block;
    padding: 0;
    margin: 0 0.3em;
    &:focus {
      color: $link-color-hover;
    }
  }
}

.fileWrapper {
  position: relative;
}
:global(.ui.form .field > label).fileControl {
  font-size: 1em;
  font-weight: normal;
  &::after {
    content: none;
  }
}
.fileInput {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  left: 1.2em !important;
  top: 100% !important;
  border: none !important;
  padding: 0 !important;
  opacity: 0 !important;

  &:focus + .fileControl {
    box-shadow: 0 0 0 1px $base-theme inset, $input-active-box-shadow !important;

    &:not(:global(.primary)) {
      color: $base-theme !important;
      border-color: $base-theme;
    }
  }
}

:global(.ui.input).inlineSelect {
  width: auto !important;
  margin: 0;
  &:not(:first-child) {
    margin-left: 0.2em;
  }
  &:not(:last-child) {
    margin-right: 0.2em;
  }
}

.tableBorder {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: math.div(2rem, 7);
}

.loaderContainer {
  &.fillParent {
    overflow: auto;
    height: 100%;
  }
  &.singleLine {
    overflow: auto;
  }

  &:global(.collapsing) {
    width: auto;
    display: table;
  }

  position: relative;
  width: 100%;
  margin: 12px 0;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  &.loading {
    // copy segment loading

    .tableContainer {
      opacity: 0.5;
    }
    .loader {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 0;
      width: 0;
      display: flex;
      align-items: center;
    }
  }
}

.tableContainer {
  display: block;
  width: 100%;

  :global {
    .ui.table {
      border: none;
      @include media-tablet {
        // sticky position 对 thead 无效
        th {
          position: sticky;
          top: 0;
          z-index: 2;
        }
      }
    }
  }
}

.nestedTableLv1 {
  @include subTableHeader(1);
}
.nestedTableLv2 {
  @include subTableHeader(2);
}

.inlineDivider {
  margin: $margin-lg 0;
  @include media-tablet {
    display: inline-block;
    vertical-align: middle;
    height: 2em;
    border-right: 1px solid $muted-separator-color;
    margin: 0 0.75em;
  }
}
.fileWrapper,
:global(.ui.button) {
  + .inlineDivider {
    // 0.75 - 0.25
    margin-left: 0.5em;
  }
}

:global(.ui.modal > .content) {
  &.attachedModalContent {
    padding: 0 !important;

    > :global(.ui.message) {
      box-shadow: none;
      margin-left: 0;
    }
  }
}

.controlledDropdown {
  &:global(.ui.popup) {
    padding: 0;
    font-size: 1rem;
    :global(.menu) {
      display: block;
      position: relative;
      border: none;
      box-shadow: none;
    }
  }
}
