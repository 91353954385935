$tds: false;
@import 'styles/variables';
.pagination {
  white-space: nowrap;
  input,
  select {
    padding-top: 0.45em !important;
    padding-bottom: 0.45em !important;
  }
  .info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pager {
    margin-left: $margin-sm;
  }
  .page {
    margin-right: $margin-lg;
    .pageInput {
      width: 4.5em;
    }
    > span {
      vertical-align: middle;
    }
  }
  .pageButton {
    min-width: 4em;
  }
}

.loadMore {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  text-align: center;

  .allLoaded {
    color: $muted-color;
    font-size: 13px;
  }
}
