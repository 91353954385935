$tds: false;
.name {
  font-size: 1.4em;
}

.centered {
  text-align: center;
}

.instruction {
  font-size: 1.15em;
}
