$tds: false;
@use "sass:math";

@import 'styles/variables';

$margin: $margin-lg;

.container {
  position: relative;
  display: inline-block;
  margin-top: ($margin * 0.5);
  margin-bottom: ($margin * 0.5);

  &:not(:last-child) {
    margin-right: $margin;
  }
}

.label {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  cursor: pointer;
  padding: math.div(10em, 14);
  border: 1px solid #ddd;
  border-radius: math.div(4em, 14);

  &:hover {
    border-color: #999;
  }

  &.checked {
    color: $base-theme;
    border-color: $base-theme;
  }
}
:global(fieldset:disabled) .label,
.label.disabled {
  opacity: 0.35;
  border-color: #ddd;
  cursor: not-allowed;
}

.radioInput {
  opacity: 0 !important;
  z-index: 0;
  position: absolute;
  bottom: -1px;
  left: 50%;
  height: 1px;
  width: 1px;

  &:focus + .label {
    color: $base-theme;
    border-color: $base-theme;
    box-shadow: $input-active-box-shadow;
  }
}

.groupContainer {
  margin-top: (-$margin * 0.5);
  margin-bottom: (-$margin * 0.5);
  display: flex;
  flex-wrap: wrap;
  &.vertical {
    flex-direction: column;
  }
}
