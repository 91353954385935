$tds: false;
@import 'styles/variables';

.breadcrumb {
  margin-bottom: 1em !important;
}

.statToolbar {
  margin-bottom: $margin-xl;
}

.appName {
  font-size: 1.4em;
}
