$tds: false;
@use "sass:math";

@import 'styles/variables';
.page {
  height: if($tds, unset, 100%);
  display: flex;
  flex-direction: column;
  width: 100%;

  .container {
    @include container;
    position: relative;
  }
  .fixed {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.header {
  background-color: #f5f5f5;
  position: relative;
  border-bottom: 1px solid $separator-color;
  @include container;

  .subTitle {
    font-size: math.div(6em, 7);
    color: $muted-color;
    text-transform: uppercase;

    :global {
      .ui.breadcrumb {
        line-height: unset;
      }
    }
  }

  .title {
    display: inline;
    font-size: 1.6em;
    font-weight: normal;
  }
  .meta {
    margin-left: 1em;
    color: $muted-color;
  }
}

.errorPage {
  &.centered {
    text-align: center;
  }

  overflow: hidden;
  justify-content: center;

  @if ($tds) {
    height: 100vh !important;
  }

  @if ($tds) {
    .title {
      margin: 20px 0 $margin 0;
      font-size: 16px;
      font-weight: normal;
      color: $gray-darker;
    }
  }

  .img {
    font-size: clamp(300px, MAX(50vh, 50vw), 500px);
    opacity: 0.04;

    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .explaination,
  .operations {
    font-size: if($tds, unset, 16px);
  }
  .explaination {
    line-height: 1.5;
  }
  .operations {
    margin-top: 1em;
  }
}
