$tds: false;
.loadingPage {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 96px;
    height: 96px;
    margin-bottom: 48px;
  }
}
