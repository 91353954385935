$tds: false;
@use "sass:math";

@import 'styles/variables';

$card-width: 400px;
$min-media-width: $card-width * 3;
$card-padding: 1em;

.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .left {
    width: 100%;
    @media (min-width: $min-media-width) {
      width: 0;
    }
    flex: 1 0 0;
  }

  .right {
    flex-basis: 100%;
    @media (min-width: $min-media-width) {
      flex: 1 0 300px;
      max-width: 300px;
      margin-left: 2.5em;
    }
  }
}

.appsToolbar {
  margin-bottom: $card-padding * 1.2;
}
.displayAll {
  padding: 0 $card-padding;
  margin-bottom: 1em;
  text-align: center;
}
.appsContainer {
  min-height: 5em;
  position: relative; // for loading
  margin-left: -$card-padding;
  margin-right: -$card-padding;
  display: flex;
  flex-wrap: wrap;
}

.placeholder {
  :global {
    .ui.placeholder {
      margin: $margin-sm 0;
    }
  }

  .leftPlaceholder {
    font-size: 1.24em;
    width: 75%;
    display: inline-block;
    margin-bottom: 0px !important;
  }
  .rightPlaceholder {
    display: block;
    height: 30px;
    width: 60px;
    float: right;
    margin-top: 0 !important;
  }
  .shortcutPlaceholder {
    display: block;
    height: 24px;
  }
}

.groupTitle {
  display: flex;
  flex-basis: 100%;
  padding: 0 $padding;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  height: 2em;
  align-items: center;
  margin-bottom: $margin-xl;
}
.recentlyUsed {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.recentlyUsedIcon {
  transform: scale(0.8);
  transform-origin: center;
  transition: ease 0.1s;
  margin-top: 0;
}
.recentlyUsedIconActive {
  transform: scale(0.8) rotate(90deg);
}

.noMatches {
  padding: $padding;
  width: 100%;
  line-height: 30vh;
  color: #767676;
  text-align: center;
  font-size: 1.8em;
}

.appContainer {
  flex: 1 0 100%;
  padding: 0 $card-padding $card-padding $card-padding;
  @include media-tablet() {
    flex-basis: 20%;
    min-width: $card-width;
    padding-bottom: $card-padding * 2;
  }
  &:empty {
    display: none;
    @include media-tablet() {
      display: block;
      height: 0;
      min-height: 0;
      padding: 0;
    }
  }
}

.divide {
  width: 100%;
}

.appCard {
  width: 100% !important;
  height: 100%;

  .appInfoContent {
    word-break: break-all;
    .appName {
      font-size: math.div(10rem, 7);
      margin-top: 3px !important;
    }
    .share {
      margin-left: $margin;
      white-space: nowrap;
    }
    .typeLabel {
      float: right;
    }
  }
  .quickEntryContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.25em !important;
    .link {
      font-size: 1.1em;
      opacity: 0.7;
      transition: all 0.2s ease !important;
      &:hover {
        color: $link-color-hover;
      }
      max-width: 3.6em;
      padding: 1.2em 0.5em;
      flex: 1;
      text-align: center;
      > :global(.icon) {
        margin: 0;
      }
    }
    .link.disabled {
      color: rgba(0, 0, 0, 0.4);
      font-weight: 400;
      &:hover {
        color: inherit;
      }
    }
  }

  &:hover {
    .quickEntryContent a {
      opacity: 1;
    }
  }

  .appInfoContent.disabled,
  .quickEntryContent.disabled {
    * {
      color: $link-disabled-color !important;
    }
    a {
      pointer-events: none;
      cursor: default;
    }
    // 覆盖 label 在 disabled 下的样式
    .typeLabel {
      color: $gray-light !important;
      opacity: 0.5;
    }
  }

  .extraInfo {
    font-size: 1rem !important;
  }
  .disabledReason {
    font-size: 70%;
    color: $muted-color;
  }
}

.appPrice {
  :global {
    i.icon:before {
      display: block;
      margin-top: 2px;
    }
  }
}

.radioContainer {
  width: 100%;
  .radio {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    transition: all 0.2s ease;
    padding: 0.2em;
  }
  .checkFlag {
    margin-left: 0.2em;
    margin-right: 1em;
  }
  .title {
    font-size: 1.1em;
    margin-bottom: 0.1em;
    font-weight: bold;
  }
}

.financeContainer {
  display: flex;
  flex-wrap: wrap;
  :global {
    .item {
      flex: 1 0 50%;
      min-width: max-content;
      width: inherit !important;
      margin-bottom: 0px !important;
    }
    .item > .content > .header {
      font-size: 1em !important;
    }
  }
  .action {
    margin-left: $margin;
  }
  .balance {
    font-size: 30px;
    .currency {
      margin-left: $margin-sm;
      font-size: 19px;
    }
  }

  .trendChart {
    display: inline-block;
    width: 200px;
  }
}

.newsItem {
  padding: 0.5em 0 !important;
  .link {
    margin-top: $margin-sm;
  }
  .title {
    font-size: 1em !important;
  }
}

.homeLink {
  min-width: 45px;
  @include media-tablet() {
    min-width: 230px;
  }
}

.welcome {
  font-size: 1.2em;
  min-height: 30vh;
  padding: $padding-lg 0;
  margin-bottom: 10vh;
}
