$tds: false;
@import 'styles/variables';
.toolbar {
  display: flex;
  margin-bottom: $margin-xl;
  align-items: center;
}
.card {
  width: auto !important;
  min-width: 200px;
  box-shadow: none !important;
  background-color: $muted-section-bg !important;
  .content {
    font-size: 16px;
    text-align: right;
    margin-top: $margin-xl !important;
  }
}
.container {
  margin-top: $margin-xxl;
  padding-top: $padding;
  padding-bottom: $padding;
  flex: auto;
}
.piePopup{
  cursor: pointer;
  &:hover {
    color: $link-color-hover;
  }
  .pieChart{
    margin-left: $margin-sm;
  }
}
.piePopupContent{
  min-width: 600px;
  .title{
    text-align: center;
    margin: 0;
  }
}

.totalAmount{
  margin: 0 $margin-sm;
  font-size: 1.2em !important;
}
