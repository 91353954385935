$tds: false;
@import 'styles/variables';

.left,
.right {
  flex: 1 0 0;
}
.right {
  text-align: right;
}

.container{
  width: 100% ;
  max-width: 600px ;
  margin: $margin auto;
}
