$tds: false;
@import 'styles/variables';

.fileInput {
  display: inline-block;
}

.download {
  margin-left: $margin !important;
  margin-right: $margin !important;
}

.hint {
  margin-top: 1em !important;
}

.breadcrumb {
  margin-bottom: 1em !important;
}

.statToolbar {
  margin-bottom: $margin-xl;
}

.detailedMetricsArea {
  margin: $margin-lg (-$padding);
  @include media-tablet {
    margin: $margin-lg (-$padding-lg);
  }
  &:last-child {
    margin-bottom: 0;
  }
  @include container;
  background-color: $muted-section-bg;
  .detailedMetricsToolbar {
    margin-bottom: $margin-xl * 2;
  }
}

.radioItem {
  min-width: 8em;
  font-size: 0.9em;
}
