$tds: false;
@import 'styles/variables';

.sidebar {
  width: $menu-width !important;
}

.main {
  display: flex;
  height: 100%;
  flex-direction: column;
  &.alwaysVisible {
    padding-left: $menu-width;
  }
}
