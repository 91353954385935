$tds: false;
@import 'styles/variables';

.info {
  display: flex;
  flex-wrap: wrap;
}
.basic {
  width: 100%;
  margin-bottom: 2em;
  @include media-tablet {
    width: 50%;
  }
}
.username {
  font-size: 2em;
}

.formContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  :global {
    .field {
      width: 100%;
      @include media-tablet {
        width: 48%;
      }
    }
  }
}
